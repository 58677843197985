import * as amplitude from "@amplitude/analytics-browser"

export const initAmplitude = () => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const deviceId = urlSearchParams.get("adid")

    const apiKey =
        import.meta.env.VITE_VERTICALE === "taxi"
            ? import.meta.env.VITE_AMPLITUDE_KEY_TAXI
            : import.meta.env.VITE_VERTICALE === "resto"
            ? import.meta.env.VITE_AMPLITUDE_KEY_RESTO
            : import.meta.env.VITE_AMPLITUDE_KEY_ICI

    amplitude.init(apiKey, undefined, {
        defaultTracking: {
            sessions: false,
            pageViews: false,
            formInteractions: false,
            fileDownloads: true,
        },
        deviceId: deviceId ?? undefined,
    })
}

export const getAmplitudeInstance = () => {
    return {
        deviceId: amplitude.getDeviceId() ?? null,
    }
}

// export const setAmplitudeDeviceId = (deviceId: string) => {
//     return amplitude.setDeviceId(deviceId)
// }
