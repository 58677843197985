import { lazy, Suspense } from "react"
import FallbackLoading from "@/pages/FallbackLoading.tsx"

const Results = lazy(() => import("./Results.tsx"))
const ResultsPage = () => {
    return (
        <Suspense fallback={<FallbackLoading />}>
            <Results />
        </Suspense>
    )
}

export default ResultsPage
