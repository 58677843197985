import { NewPlace } from "@/services/google/type.ts"
import { createContext, PropsWithChildren, useContext, useState } from "react"
import { useAmplitude } from "@/utils/useAmplitude.ts"

interface ISearch {
    query: string
    city: string
    results: NewPlace[]
}

type Photo = {
    id: string
    url: string
}

interface IGmapsContext {
    photos: Photo[]
    searches: ISearch[]
    checkAndSaveSearch: (search: ISearch) => void
    getGmapsPhotoFromName: (photoid: string) => Promise<string>
}

const GmapsContext = createContext<IGmapsContext>({
    photos: [],
    searches: [],
    checkAndSaveSearch: () => {},
    getGmapsPhotoFromName: () => Promise.resolve(""),
})

const GmapsContextProvider = ({ children }: PropsWithChildren) => {
    const sendAmplitudeTrack = useAmplitude()

    const [photos, setPhotos] = useState<Photo[]>([])
    const [searches, setSearches] = useState<ISearch[]>([])

    // 1. => places/ChIJ3zR33KcnVQ0RxBxeuANCT1w/photos/AUGGfZkS5aYLbBrWDLFe_1nRwaw6a_f5IFXYV2YCJ_lP-ngHZps4H5Y0p9KDbOZ0-BeL8Cf1WwdCIjo0fdmj0eWqq_W6aBndBoinme9cLmTJNQeRNj3nPhMRr700VsI01ubmLJlZReNoV79EKfP9lKDjhW1GASI7qCbyZhZv
    // 2. => places/ChIJ3zR33KcnVQ0RxBxeuANCT1w/photos/AUGGfZlcUTlU1nA5axw5A3iiYzh-RYN_zwImnATnCj3Jc_AaTfohI4AswKYugq2IItuLzCimLaSP2G8fX8-m72Ek2wYRPvLUL4I3UiKJI9hkqG1Xvq89jawfjTYaPpCLfr_kNhvsFQXuebm7YHVWFFJqlt1JBwVNy0sD08JG

    const getGmapsPhotoFromName = async (photoId: string): Promise<string> => {
        const array = photoId.split("/")
        const placeId = array[1]

        const photoExists = photos.find((p) => p.id === placeId)

        if (photoExists) {
            sendAmplitudeTrack("Track - Photo already exists", { useCache: true })
            return photoExists.url
        } else {
            const photoRequestUrl = `https://places.googleapis.com/v1/${photoId}/media?key=${
                import.meta.env.VITE_VERTICALE === "taxi"
                    ? import.meta.env.VITE_GOOGLE_API_KEY_TAXI
                    : import.meta.env.VITE_VERTICALE === "resto"
                    ? import.meta.env.VITE_GOOGLE_API_KEY_RESTO
                    : import.meta.env.VITE_GOOGLE_API_KEY_ICI
            }&maxHeightPx=450&maxWidthPx=880&skipHttpRedirect=true`

            try {
                const response = await fetch(photoRequestUrl)
                const data = await response.json()

                if (data.photoUri) {
                    const photoUrl = data.photoUri
                    setPhotos((oldPhotos) => [...oldPhotos, { id: placeId, url: photoUrl }])

                    return photoUrl
                } else {
                    sendAmplitudeTrack("Error - Fetch image", {
                        error: "No result",
                        photoRequestUrl,
                    })

                    return "/images/no-image.jpg"
                }
            } catch (error) {
                sendAmplitudeTrack("Error - Fetch image", {
                    error: error,
                    photoRequestUrl,
                })

                return "/images/no-image.jpg"
            }
        }
    }

    const checkAndSaveSearch = (search: ISearch) => {
        const existingSearch = searches.find(
            (s) => s.query === search.query && s.city === search.city && s.results.length === search.results.length,
        )

        if (existingSearch) {
            sendAmplitudeTrack("Track - Search already exists")
        } else {
            setSearches([...searches, search])
        }
    }

    return (
        <GmapsContext.Provider value={{ photos, searches, checkAndSaveSearch, getGmapsPhotoFromName }}>
            {children}
        </GmapsContext.Provider>
    )
}

const useCheckAndSaveSearch = () => useContext(GmapsContext).checkAndSaveSearch
const useGetGmapsPhotoFromName = () => useContext(GmapsContext).getGmapsPhotoFromName

export { GmapsContextProvider, useCheckAndSaveSearch, useGetGmapsPhotoFromName }
