export const dynamicValue = ({ taxi, resto, ici }: { taxi: any; resto: any; ici: any }) => {
    const verticale = import.meta.env.VITE_VERTICALE
    if (verticale === "resto") {
        return resto
    } else if (verticale === "taxi") {
        return taxi
    } else {
        return ici
    }
}
